.container {
  position: relative;
  display: inline-block;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.container .text {
  position: absolute;
  z-index: 400;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  vertical-align: middle;
  top: calc(50% - 16px);
  font-family: Arial, sans-serif;
  color: black;
  width: 30px;
  font-size: 18px;
  opacity: 0;
}

.container:hover [data-cy='user-profile-image'] {
  opacity: 0.3;
  cursor: pointer;
}

.container:hover .text {
  transition: 0.5s ease;
  opacity: 1;
  cursor: pointer;
}

.input-text-field {
  height: 36px;
  padding-top: 8px;
  padding-bottom: 8px;
  /* border-radius: 4px; */
  border: 1px solid #aaafb9;
}

.form-error {
  color: #e85355;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: bold;
}

/* User Form */

#userForm .radio-list-item {
  align-items: baseline !important;
}

#userForm .radio-list-item .radio-label {
  white-space: nowrap;
  width: unset !important;
  font-weight: 700;
}

#userForm .radio-list-item .radio-message {
  width: unset !important;
  text-align: unset !important;
}

/* SignIn Form */

#signInPage input:focus {
  outline: none;
}
